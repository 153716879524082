












import { Component, Vue } from 'vue-property-decorator';
import { metaInfo } from '@/utils/seo/metaInfo';

@Component({
  components: {},
  metaInfo: metaInfo({
    title: 'Stránka nenalezena',
    index: false
  })
})
export default class ErrorPage extends Vue {}
